import React from "react";
import "./ExperimentalBanner.css"; // Import the styles

const ExperimentalBanner = () => {
  return (
    <div className="experimental-banner">
      <p>Experimental test build, production experience may vary</p>
    </div>
  );
};

export default ExperimentalBanner;
