import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners"; // Import the spinner
import "./Gallery.css";

const Gallery = () => {
  const [artStyles, setArtStyles] = useState([]);
  const [gallery, setGallery] = useState({});
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false); // Loading state for spinner
  const photosPerPage = 9;
  const location = useLocation();

  // Fetch art styles
  useEffect(() => {
    fetch("./data/artstyles.json")
      .then((response) => response.json())
      .then((data) => setArtStyles(data.artStyles))
      .catch((error) => console.error("Error fetching art styles:", error));
  }, []);

  // Fetch gallery photos
  useEffect(() => {
    setLoading(true); // Start loading
    fetch("./data/gallery.json")
      .then((response) => response.json())
      .then((data) => {
        setGallery(data.gallery);
        setLoading(false); // End loading once the data is fetched
      })
      .catch((error) => {
        console.error("Error fetching gallery:", error);
        setLoading(false); // End loading in case of an error
      });
  }, []);

  // Check for URL query parameter or select first art style
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const styleFromUrl = query.get("style");

    if (styleFromUrl) {
      setSelectedStyle(styleFromUrl);
    } else if (artStyles.length > 0 && !selectedStyle) {
      setSelectedStyle(artStyles[0].id);
    }
  }, [location.search, artStyles, selectedStyle]);

  // Filter photos based on selected art style
  useEffect(() => {
    if (selectedStyle && gallery[selectedStyle]) {
      const filtered = gallery[selectedStyle].images || [];
      setFilteredPhotos(filtered);
      setCurrentPage(0); // Reset to first page when style changes
    }
  }, [selectedStyle, gallery]);

  const totalPages = Math.ceil(filteredPhotos.length / photosPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Get photos for the current page
  const currentPhotos = filteredPhotos.slice(
    currentPage * photosPerPage,
    (currentPage + 1) * photosPerPage,
  );

  // Fill empty spaces if there are fewer than 9 photos
  const emptySlots = photosPerPage - currentPhotos.length;

  return (
    <div className="gallery-container">
      <div className="art-styles-column">
        <ul>
          {artStyles.map((style) => (
            <li
              key={style.id}
              className={`style-item ${selectedStyle === style.id ? "active" : ""}`}
              onClick={() => setSelectedStyle(style.id)}
            >
              {style.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="photos-grid-column">
        <div className="grid-container">
          {loading ? (
            <div className="spinner-container">
              <ClipLoader color="#36d7b7" loading={loading} size={50} />
            </div>
          ) : (
            <>
              {currentPhotos.map((photo, index) => (
                <div key={index} className="grid-item">
                  <img src={photo.url} alt={photo.title} />
                </div>
              ))}
              {/* Fill empty slots with rectangles */}
              {Array.from({ length: emptySlots }).map((_, index) => (
                <div key={`empty-${index}`} className="grid-item empty"></div>
              ))}
            </>
          )}
        </div>
        {/* Pagination controls */}
        <div className="pagination-controls">
          <button onClick={handlePreviousPage} disabled={currentPage === 0}>
            Назад
          </button>
          <span>
            Сторінка {currentPage + 1} з {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage >= totalPages - 1}
          >
            Наступна
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
