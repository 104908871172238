import React from "react";
import SizeChooser from "../components/SizeChooser";
import RoomWithUserPhoto from "../components/RoomWithUserPhoto";
import CalibrationSequence from "../dev/CalibrationSequence";

const DIY = () => {
  const roomImage = "/img/room/20x30.jpeg";
  const userImage = "/img/room/20x30.jpeg";

  const greenSquare = {
    x: 278,
    y: 141,
    width: 73,
    height: 106,
  };

  return (
    <div>
      <CalibrationSequence />
    </div>
  );
};

export default DIY;
