import React from "react";
import { useNavigate } from "react-router-dom";
import "./ProductCard.css";

const ProductCard = ({ image, styleId, description }) => {
  const navigate = useNavigate();

  const handleDetailClick = () => {
    // Navigate to a URL with the styleId in the path, e.g., /product-detail/<styleId>
    navigate(`/styles?styleId=${styleId}`);
  };

  return (
    <div className="product-card">
      <img src={image} alt="Product" className="product-image" />
      <p className="product-description">{description}</p>
      <button className="detail-button" onClick={handleDetailClick}>
        Докладніше
      </button>
    </div>
  );
};

export default ProductCard;
