import React, { useState, useEffect } from "react";
import { db } from "../../core/firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import "./AdminOrders.css";

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [filterStatus, setFilterStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [orderField, setOrderField] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [showContactInfoPopup, setShowContactInfoPopup] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [editableTotals, setEditableTotals] = useState({});

  useEffect(() => {
    fetchAllOrders();
  }, []);

  useEffect(() => {
    // Recalculate total pages whenever ordersPerPage changes
    const newTotalPages = Math.ceil(filteredOrders.length / ordersPerPage);
    setTotalPages(newTotalPages);
    setCurrentPage(1); // Reset to first page when ordersPerPage changes
  }, [ordersPerPage]);

  const fetchAllOrders = async () => {
    setLoading(true);
    const ordersRef = collection(db, "orders");
    const querySnapshot = await getDocs(ordersRef);

    const fetchedOrders = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Initialize editable totals for each order
    const newEditableTotals = {};
    fetchedOrders.forEach((order) => {
      newEditableTotals[order.id] = order.total;
    });

    setOrders(fetchedOrders);
    setEditableTotals(newEditableTotals);
    setTotalPages(Math.ceil(fetchedOrders.length / ordersPerPage));
    setLoading(false);
  };

  const resetPagination = () => {
    setCurrentPage(1);
  };

  const loadNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const loadPreviousPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const resetFilters = () => {
    setFilterStatus("");
    setSearchTerm("");
    setOrderField("createdAt");
    setOrderDirection("desc");
    setOrdersPerPage(10);
    resetPagination();
  };

  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  const handleTotalChange = async (orderId) => {
    const orderRef = doc(db, "orders", orderId);
    await updateDoc(orderRef, { total: editableTotals[orderId] });
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId
          ? { ...order, total: editableTotals[orderId] }
          : order,
      ),
    );
    alert("Total price updated successfully!");
  };

  const openStatusPopup = (order) => {
    setCurrentOrder(order);
    setShowStatusPopup(true);
  };

  const openContactInfoPopup = async (userId) => {
    const userDoc = await getDoc(doc(db, "users", userId));
    if (userDoc.exists()) {
      setContactInfo(userDoc.data());
      setShowContactInfoPopup(true);
    }
  };

  const handleStatusChange = async (newStatus) => {
    if (currentOrder) {
      const orderRef = doc(db, "orders", currentOrder.id);
      await updateDoc(orderRef, { status: newStatus });
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === currentOrder.id
            ? { ...order, status: newStatus }
            : order,
        ),
      );
      setShowStatusPopup(false);
      alert("Order status updated successfully!");
    }
  };

  // Apply filters and pagination to orders
  const filteredOrders = orders
    .filter(
      (order) =>
        (!filterStatus || order.status === filterStatus) &&
        (order.id.includes(searchTerm) ||
          (order.userInfo?.fullName || "")
            .toLowerCase()
            .includes(searchTerm.toLowerCase())),
    )
    .sort((a, b) => {
      if (orderField === "total") {
        return orderDirection === "asc" ? a.total - b.total : b.total - a.total;
      } else {
        return orderDirection === "asc"
          ? a.createdAt.seconds - b.createdAt.seconds
          : b.createdAt.seconds - a.createdAt.seconds;
      }
    });

  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * ordersPerPage,
    currentPage * ordersPerPage,
  );

  return (
    <div className="admin-orders-container">
      <h2>Manage Orders</h2>

      {/* Filter Bar */}
      <div className="filter-bar">
        <input
          type="text"
          placeholder="Search by Order ID or User Name"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            resetPagination();
          }}
          className="search-input"
        />
        <select
          value={filterStatus}
          onChange={(e) => {
            setFilterStatus(e.target.value);
            resetPagination();
          }}
          className="filter-select"
        >
          <option value="">All Statuses</option>
          <option value="created">Created</option>
          <option value="Received">Received</option>
          <option value="Paid">Paid</option>
          <option value="Delivery">Delivery</option>
          <option value="Cancelled">Cancelled</option>
        </select>
        <select
          value={orderField}
          onChange={(e) => {
            setOrderField(e.target.value);
            resetPagination();
          }}
          className="filter-select"
        >
          <option value="createdAt">Date Created</option>
          <option value="total">Total Amount</option>
          <option value="status">Status</option>
        </select>
        <select
          value={orderDirection}
          onChange={(e) => {
            setOrderDirection(e.target.value);
            resetPagination();
          }}
          className="filter-select"
        >
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
        <select
          value={ordersPerPage}
          onChange={(e) => {
            setOrdersPerPage(parseInt(e.target.value));
            resetPagination();
          }}
          className="filter-select"
        >
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="15">15 per page</option>
          <option value="20">20 per page</option>
          <option value="25">25 per page</option>
        </select>
        <button onClick={resetFilters} className="filter-reset-button">
          Reset Filters
        </button>
      </div>

      {/* Orders List */}
      {paginatedOrders.map((order) => (
        <div key={order.id} className="admin-order-card">
          <div className="admin-order-summary">
            <h3>Order ID: {order.id}</h3>
            <p>
              <strong>Status:</strong> {order.status}
            </p>
            <p>
              <strong>Total:</strong>
              <input
                type="number"
                value={editableTotals[order.id] ?? order.total}
                onChange={(e) => {
                  const newValue = parseFloat(e.target.value);
                  setEditableTotals((prev) => ({
                    ...prev,
                    [order.id]: isNaN(newValue) ? order.total : newValue,
                  }));
                }}
              />
              <button onClick={() => handleTotalChange(order.id)}>
                Update Total
              </button>
            </p>
            <p>
              <strong>User:</strong> {order.userInfo?.fullName || "Unknown"}
            </p>
            <button onClick={() => toggleOrderDetails(order.id)}>
              Toggle Details
            </button>
            <button onClick={() => openStatusPopup(order)}>
              Change Status
            </button>
            <button onClick={() => openContactInfoPopup(order.userId)}>
              Контакти/Доставка
            </button>
          </div>

          {expandedOrder === order.id && (
            <div className="admin-order-details">
              <h4>Order Details:</h4>
              <ul>
                {order.items.map((item) => (
                  <li key={item.id}>
                    <p>
                      <strong>Style:</strong> {item.selectedStyle}
                    </p>
                    <p>
                      <strong>Size:</strong> {item.selectedSize}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {item.quantity}
                    </p>
                    <p>
                      <strong>Price:</strong> {item.price} UAH
                    </p>
                    <img
                      src={expandedOrder === order.id ? item.imageURL : ""}
                      alt={item.selectedStyle}
                      className="order-item-image"
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          onClick={loadPreviousPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>

        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            className={`pagination-button ${currentPage === i + 1 ? "active" : ""}`}
            onClick={() => goToPage(i + 1)}
          >
            {i + 1}
          </button>
        ))}

        <button
          onClick={loadNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AdminOrders;
