import React, { useState, useEffect } from "react";
import { auth, db } from "../core/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fetchCities, fetchDepartments } from "../core/novapost"; // Import the client
import "./ChangeMyInfo.css"; // Import the CSS file

const ChangeMyInfo = ({ callbackOnComplete }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    postalNumber: "",
  });
  const [authProvider, setAuthProvider] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [searchDepartment, setSearchDepartment] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [showCitySuggestions, setShowCitySuggestions] = useState(false);
  const [showDepartmentSuggestions, setShowDepartmentSuggestions] =
    useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Determine the auth provider and pre-fill email and phone number
        const provider = currentUser.providerData[0].providerId;
        setAuthProvider(provider);

        // Pre-fill email and phoneNumber only if available from Firebase
        setUserData((prevState) => ({
          ...prevState,
          email: currentUser.email || prevState.email,
          phoneNumber: currentUser.phoneNumber || prevState.phoneNumber,
        }));

        // Fetch user data from Firestore
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData((prevState) => ({
            ...prevState,
            fullName: data.fullName || "",
            phoneNumber: data.phoneNumber || prevState.phoneNumber, // Prefill phone number from Firestore
            postalNumber: data.postalNumber || "",
          }));

          // If the user has a saved postal number, pre-fill the selected department
          if (data.postalNumber) {
            setSearchDepartment(data.postalNumber);
          }
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSearchCity = async (e) => {
    const searchValue = e.target.value;
    setSearchCity(searchValue);

    if (typingTimeout) {
      clearTimeout(typingTimeout); // Clear previous timeout
    }

    if (searchValue.length >= 3) {
      setTypingTimeout(
        setTimeout(async () => {
          try {
            const citiesData = await fetchCities(); // Fetch cities
            const filtered = citiesData.filter((city) =>
              city.Description.toLowerCase().includes(
                searchValue.toLowerCase(),
              ),
            );
            setFilteredCities(filtered);
            setShowCitySuggestions(true);
          } catch (error) {
            console.error("Error fetching cities:", error);
          }
        }, 1000), // Delay API call by 1 second
      );
    } else {
      setShowCitySuggestions(false); // Hide suggestions if less than 3 characters
    }
  };

  const handleCitySelection = async (city) => {
    setSearchCity(city.Description);
    setSelectedCity(city);
    setShowCitySuggestions(false);
    setLoadingDepartments(true);

    try {
      const departmentsData = await fetchDepartments(city.Ref); // Fetch departments
      setFilteredDepartments(departmentsData);
      setShowDepartmentSuggestions(true);
      setLoadingDepartments(false);
    } catch (error) {
      console.error("Error fetching departments:", error);
      setLoadingDepartments(false);
    }
  };

  const handleSearchDepartment = (e) => {
    const searchValue = e.target.value;
    setSearchDepartment(searchValue);

    if (typingTimeout) {
      clearTimeout(typingTimeout); // Clear previous timeout
    }

    if (searchValue.length >= 3) {
      setTypingTimeout(
        setTimeout(() => {
          const filtered = filteredDepartments.filter((department) =>
            department.Description.toLowerCase().includes(
              searchValue.toLowerCase(),
            ),
          );
          setFilteredDepartments(filtered);
          setShowDepartmentSuggestions(true);
        }, 1000), // Delay API call by 1 second
      );
    } else if (searchValue.length === 0) {
      setShowDepartmentSuggestions(true); // Show all options if no search input
    } else {
      setShowDepartmentSuggestions(false); // Hide suggestions if less than 3 characters
    }
  };

  const handleDepartmentSelection = (department) => {
    setSearchDepartment(department.Description);
    setSelectedDepartment(department);
    setShowDepartmentSuggestions(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        const updatedData = { ...userData };
        if (selectedDepartment) {
          updatedData.postalNumber = `${selectedDepartment.Description}, ${selectedDepartment.CityDescription}`;
        }
        await setDoc(doc(db, "users", user.uid), updatedData);
        alert("Контактні дані оновлені!");

        // Callback function to parent component, if no callback function or not a function, do nothing
        if (typeof callbackOnComplete === "function") {
          callbackOnComplete();
        }
      } catch (error) {
        console.error("Error updating user info: ", error);
        alert("Failed to update info.");
      }
    }
  };

  return (
    <div>
      <h2>Change My Info</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Прізвище Ім'я по батькові:</label>
          <input
            type="text"
            name="fullName"
            value={userData.fullName}
            onChange={(e) =>
              setUserData({ ...userData, fullName: e.target.value })
            }
            required
          />
        </div>

        {/* Phone Number (Editable if authenticated via Google) */}
        <div>
          <label>Номер телефону:</label>
          <input
            type="tel"
            name="phoneNumber"
            value={userData.phoneNumber}
            onChange={(e) =>
              setUserData({ ...userData, phoneNumber: e.target.value })
            } // Keep value changeable
            disabled={authProvider === "phone"} // Only disable if authenticated via phone
            style={{
              backgroundColor: authProvider === "phone" ? "#f0f0f0" : "white",
            }}
          />
        </div>

        {/* Email (Greyed out if set by Firebase Auth) */}
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={userData.email}
            disabled={
              authProvider === "google.com" || authProvider === "apple.com"
            }
            style={{
              backgroundColor:
                authProvider === "google.com" || authProvider === "apple.com"
                  ? "#f0f0f0"
                  : "white",
            }}
          />
        </div>

        <div>
          <label>Місто доставки:</label>
          <input
            type="text"
            placeholder="Почни вводити своє місто..."
            value={searchCity}
            onChange={handleSearchCity}
          />
          {showCitySuggestions && filteredCities.length > 0 && (
            <ul className="suggestions">
              {filteredCities.map((city) => (
                <li key={city.Ref} onClick={() => handleCitySelection(city)}>
                  {city.Description}
                </li>
              ))}
            </ul>
          )}
        </div>

        {selectedCity && (
          <div>
            <label>Відділення:</label>
            <input
              type="text"
              placeholder="Обери своє відділення..."
              value={searchDepartment}
              onChange={handleSearchDepartment}
              onFocus={() => setShowDepartmentSuggestions(true)}
            />
            {showDepartmentSuggestions && filteredDepartments.length > 0 && (
              <ul className="suggestions">
                {filteredDepartments.map((department) => (
                  <li
                    key={department.Ref}
                    onClick={() => handleDepartmentSelection(department)}
                  >
                    {department.Description}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {userData.postalNumber && !selectedDepartment && (
          <div>
            <p>
              <strong>Обране відділення:</strong> {userData.postalNumber}
            </p>
          </div>
        )}

        {selectedDepartment && (
          <div>
            <p>
              <strong>Адреса відділення:</strong>{" "}
              {selectedDepartment.Description},{" "}
              {selectedDepartment.CityDescription}
            </p>
          </div>
        )}

        <button type="submit">Оновити свою інформацію</button>
      </form>
    </div>
  );
};

export default ChangeMyInfo;
