// telegramNotificationService.js
const sendTelegramNotification = async (message) => {
  const telegramBotToken = "8054326101:AAGsbQoekuEhQCwRx6-0Zj7ZDJ3yfB9nQ8o"; // Store this securely, e.g., in environment variables
  const chatId = "-1002382553815"; // Replace with the chat ID you want to send the message to
  const apiUrl = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;

  const payload = {
    chat_id: chatId,
    text: message,
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Telegram API error: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Notification sent:", data);
  } catch (error) {
    console.error("Error sending Telegram notification:", error);
  }
};

const sendTelegramPhotos = async (photos) => {
  const telegramBotToken = "8054326101:AAGsbQoekuEhQCwRx6-0Zj7ZDJ3yfB9nQ8o"; // Securely store in environment variables
  const chatId = "-1002382553815"; // Replace with your chat ID
  const apiUrl = `https://api.telegram.org/bot${telegramBotToken}/sendMediaGroup`;

  // Prepare the array of media items for sendMediaGroup
  const mediaGroup = photos.map((p, index) => ({
    type: "photo",
    media: p[0],
    caption: `Фото із замовлення: ` + p[1], // Optional caption for each photo
  }));

  const payload = {
    chat_id: chatId,
    media: mediaGroup,
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Telegram API error: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Photos sent:", data);
  } catch (error) {
    console.error("Error sending Telegram photos:", error);
  }
};

export { sendTelegramNotification, sendTelegramPhotos };
