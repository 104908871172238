import React from "react";
import ArtStylesComponent from "../components/ArtStyles";

const Styles = () => {
  return (
    <div>
      <ArtStylesComponent />
    </div>
  );
};

export default Styles;
