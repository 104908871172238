import React from "react";
import Gallery from "../components/Gallery";

const Museum = () => {
  return (
    <div>
      <Gallery />
    </div>
  );
};

export default Museum;
