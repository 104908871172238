import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Other.css";

const sections = {
  order: {
    title: "Оформлення замовлення",
    content: (
      <>
        <h3>Як зробити замовлення з Artme Holst?</h3>
        <ul>
          <li>Через месенджери Telegram або Viber</li>
          <li>Через електронну пошту contact_us@artmeholst.com</li>
          <li>На сайті через розділи:</li>
          <ul>
            <li>«Замовити з промальовкою»</li>
            <li>«Замовити без промальовки»</li>
          </ul>
          <li>За номерами телефону: 097123123123 або 094315123</li>
        </ul>
        <p>
          Після отримання та обробки замовлення оформленим любим із описаних
          вище способів, менеджер підсумує всі необіхдні деталі, з якими
          потрібно ознайомитися, перевірити та підтвердити.
        </p>
        <p>
          Інформація про виріб та стиль, який Вас цікавить, кількість
          людей/об’єктів на фото, розміри та будь-які інші додаткові коментарі
          від замовника – дуже важливі, оскільки все це впливає на складність та
          варість роботи, до того ж ми прагнемо як найкраще передати Вашу емоцію
          на картину з врахуванням усіх деталей.
        </p>
        <p>
          У разі, якщо в послугах художника потреби немає і Ви маєте готове
          фото, яке потрібно нанести на полотно, у розділі «Замовити без
          промальовки» прикріпіть макети та/або файли для розробки макета. У
          разі необхідності надайти на сайті або особисто менеджеру опис
          замовлення.
        </p>
        <p>
          Якщо файлів декілька, заархівуйте їх та надішліть 1 архів або
          посилання на файлообмінник. Не забудьте вказати контактну інформацію.
        </p>
        <p>Під час оформлення замовлення користуйтеся підказками.</p>
      </>
    ),
  },
  paymentAndDelivery: {
    title: "Оплата і доставка",
    content: (
      <>
        <h3>Доставка</h3>
        <h3>
          1. Доставка здійснюється будь-яким зручним для Вас перевізником:
        </h3>
        <ul>
          <li>«Нова Пошта»</li>
          <li>«Укрпошта»</li>
          <li>«Meest»</li>
          <li>«Ipost»</li>
          <li>тощо</li>
        </ul>
        <p>
          Однак, прохання зауважити, картини великих розмірів перевізники
          відмовляються доставляти до своїх відділень (наприклад відділення Нова
          Пошта до 30 кг може прийняти максимальний розмір картини 60х80 см), в
          такому разі можемо розглянути кур’єрську доставку до дверей.
        </p>
        <h3>2. Доставка службою таксі</h3>
        <h3>3. Самовивіз</h3>
        <p>
          Якщо вам зручно забрати замовлення прямо у нас, ви можете обрати
          самовивіз. Готова продукція чекатиме на Вас та буде доступна для
          самовивозу навіть в позаробочий час (за індивідуальною домовленістю).
        </p>

        <h3>Оплата</h3>
        <p>Оплата реквізитами:</p>
        <p>
          Після затвердженого замовлення на друк надішлють реквізити для оплати
          (номер карти або IBAN), за якими ви можете зробити переказ. Платежі
          зазвичай надходять протягом декількох хвилин, однак з різних,
          незалежних від нас, причин, відбуваються затримки, тому краще
          повідомити про оплату надіславши копію квитанції, де видно код
          квитанціїї який можна перевірити у державному сервісі. В такий спосіб
          не доведеться чекати на зарахування.
        </p>
        <p>
          У призначенні платежу вкажіть контактний номер телефону який вказували
          при замовленні. Якщо Вам не вдалося вказати ці реквізити, повідомте
          нам про оплату, прикріпивши до нього копію квитанції.
        </p>
        <h3>Оплата готівкою</h3>
        <p>
          Оплатити замовлення на друк можна готівкою в офісі в Києві. Наша
          адреса: м. Київ, вул. Миколи голего, 7-В
        </p>
        <p>
          Також (!), прохання зауважити, команда «ARTME Holst» працює лише за
          передоплатою у розмірі 200грн. Ми з розумінням ставимося до нашого
          замовника та цінуємо Ваш час, тож очікуємо що і Ви з розумінням
          поставитеся до нашого часу, використаного на погодження всіх нюансів
          замовлення.
        </p>
      </>
    ),
  },
  productionTime: {
    title: "Терміни виготовлення",
    content: (
      <>
        <h3>Терміни виготовлення</h3>
        <p>
          Орієнтовно терміни виготовлення Вашого замовлення варіюються від 1-2
          робочих днів, в залежності від складності замовлення та швидкості
          погодження з замовником усіх деталей та правок.
        </p>
        <p>
          Ми завжди намагаємося проявляти чуйність та клієнтоорієнтовність до
          нашого замовника, якщо у Вас надто термінове замовлення, наші
          художники завжди готові взяти те чи інше замовлення в пріорітет, або ж
          навіть в позаробочий час, наша команда завжди готова піти на зустріч
          та проявити гнучкість в кожному замовленні!
        </p>
        <p>
          Також, прохання зауважити, що терміни доставки готового замовлення не
          залежать від нас, та можуть збільшити час очікування, в залежності від
          перевізника/міста/способу доставки.
        </p>
      </>
    ),
  },
};

const Other = () => {
  const [activeSection, setActiveSection] = useState("order");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");
    if (section && sections[section]) {
      setActiveSection(section);
    }
  }, [location.search]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    navigate(`?section=${section}`);
  };

  return (
    <div className="other-container">
      {/* Column 1: Logo and Navigation Buttons */}
      <div className="other-column1">
        <img src="/img/Logo_girl_trans.png" alt="Logo" className="other-logo" />
        <div className="other-buttons">
          <button
            className={`other-button ${activeSection === "order" ? "active" : ""}`}
            onClick={() => handleSectionChange("order")}
          >
            Оформлення замовлення
          </button>
          <button
            className={`other-button ${activeSection === "paymentAndDelivery" ? "active" : ""}`}
            onClick={() => handleSectionChange("paymentAndDelivery")}
          >
            Оплата і доставка
          </button>
          <button
            className={`other-button ${activeSection === "productionTime" ? "active" : ""}`}
            onClick={() => handleSectionChange("productionTime")}
          >
            Терміни виготовлення
          </button>
        </div>
      </div>

      {/* Column 2: Content Display */}
      <div className="other-column2">
        <h2>{sections[activeSection].title}</h2>
        <div className="other-content">{sections[activeSection].content}</div>
      </div>
    </div>
  );
};

export default Other;
