// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBgFVpKQeUfRpWG7inbnhl1H0wthpfrQnM",
  authDomain: "artme-4334b.firebaseapp.com",
  projectId: "artme-4334b",
  storageBucket: "artme-4334b.appspot.com",
  messagingSenderId: "72099710588",
  appId: "1:72099710588:web:daf25495f7827ca513abf9",
  measurementId: "G-VE0F1EQ9KM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and set up the Google provider
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com"); // Apple OAuth provider

// Initialize Firestore
const db = getFirestore(app); // Initialize Firestore
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, googleProvider, appleProvider, db, storage }; // Export the Firestore instance
