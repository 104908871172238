import React, { useEffect, useState } from "react";
import { auth, db } from "../core/firebase"; // Import Firebase auth and Firestore
import { doc, getDoc } from "firebase/firestore";

const MyInfo = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    postalNumber: "",
  });
  const [authMethod, setAuthMethod] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Set the authentication provider (Google, Apple, Phone)
        const provider = currentUser.providerData[0].providerId;
        if (provider === "google.com") {
          setAuthMethod("Google");
        } else if (provider === "apple.com") {
          setAuthMethod("Apple");
        } else if (provider === "phone") {
          setAuthMethod("Phone");
        }

        // Fetch user info from Firestore
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data()); // Load the user data from Firestore
        }
        setLoading(false); // Stop loading once data is fetched
      } else {
        setLoading(false); // In case the user is not logged in
      }
    });

    return () => unsubscribe(); // Cleanup the listener when the component unmounts
  }, []);

  if (loading) {
    return <p>Завантажуємо вашу контактну інформацію...</p>;
  }

  return (
    <div>
      <h2>My Info</h2>
      {user ? (
        <div>
          <p>
            <strong>Метод входу:</strong> {authMethod}
          </p>
          <p>
            <strong>Google/Apple Ім'я:</strong> {user.displayName}
          </p>

          {/* Display the user data fetched from Firestore */}
          <p>
            <strong>П.І.Б.:</strong> {userData.fullName || "Не вказано"}
          </p>
          <p>
            <strong>Номер Телефону:</strong>{" "}
            {userData.phoneNumber || "Не вказано"}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Відділення нової пошти:</strong>{" "}
            {userData.postalNumber || "Не вказано"}
          </p>
        </div>
      ) : (
        <p>Інформація користувача недоступна.</p>
      )}
    </div>
  );
};

export default MyInfo;
