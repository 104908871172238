import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import Sidebar from "./Sidebar";
import AdminOrders from "./AdminOrders"; // Import AdminOrders component
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState("Dashboard");

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims;
        if (claims.admin || claims.superAdmin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    });
  }, []);

  if (!isAdmin) {
    return <div>You do not have access to this page.</div>;
  }

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Dashboard":
        return <div>Welcome to the Admin Dashboard</div>;
      case "Orders":
        return <AdminOrders />; // Render AdminOrders component when Orders is selected
      case "Users":
        return <div>Manage Users Component Coming Soon</div>;
      case "Integrations":
        return <div>Integrations Component Coming Soon</div>;
      default:
        return <div>Select a section from the menu</div>;
    }
  };

  return (
    <div className="admin-dashboard-container">
      <Sidebar
        onSelectComponent={setSelectedComponent}
        selectedComponent={selectedComponent}
      />
      <div className="admin-dashboard-content">{renderComponent()}</div>
    </div>
  );
};

export default AdminDashboard;
