import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "./UserContext";
import {
  fetchCartItems,
  removeItemFromCart,
  clearCart,
  updateItemQuantity,
} from "../services/cartService";
import { useNavigate } from "react-router-dom";
import { db } from "../core/firebase";
import { doc, getDoc, setDoc, collection, addDoc } from "firebase/firestore";
import "./Cart.css"; // Cart styles
import ChangeMyInfo from "./ChangeMyInfo"; // Reuse ChangeMyInfo component
import {
  sendTelegramNotification,
  sendTelegramPhotos,
} from "../core/telegramNotification";

const Cart = () => {
  const user = useContext(UserContext);
  const [cartItems, setCartItems] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [missingInfo, setMissingInfo] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false); // Popup state
  const [orderProcessing, setOrderProcessing] = useState(false);
  const [updating, setUpdating] = useState(false); // Optional: Track updating state
  const popupRef = useRef(null); // Reference for the popup
  const navigate = useNavigate();

  // Fetch user info and cart items when component mounts
  useEffect(() => {
    const loadUserData = async () => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserInfo(userData); // Set user info
        } else {
          setMissingInfo(true); // Flag as missing if no user data
        }

        const items = await fetchCartItems(user.uid); // Fetch cart items from Firestore
        setCartItems(items);
        setLoading(false);
      }
    };

    loadUserData();
  }, [user]);

  // Handle removing an item from the cart
  const handleRemove = async (itemId) => {
    if (user) {
      await removeItemFromCart(user.uid, itemId); // Remove item from Firestore
      const updatedItems = cartItems.filter((item) => item.id !== itemId);
      setCartItems(updatedItems);
    }
  };

  // Function to set showInfoPopup to false
  const handleClosePopup = () => {
    setShowInfoPopup(false);
  };

  // Handle clearing the cart
  const handleClearCart = async () => {
    if (user) {
      await clearCart(user.uid); // Clear cart in Firestore
      setCartItems([]); // Clear local state
    }
  };

  // Handle updating item quantity
  const handleQuantityChange = async (itemId, newQuantity) => {
    if (newQuantity > 0 && user) {
      await updateItemQuantity(user.uid, itemId, newQuantity); // Update quantity in Firestore
      const updatedItems = cartItems.map((item) =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item,
      );
      setCartItems(updatedItems);
    }
  };

  // Handle finalizing the order
  const handleFinalizeOrder = async () => {
    if (cartItems.length > 0) {
      // Ensure all required fields are filled before proceeding with the order
      if (
        !userInfo ||
        !userInfo.fullName ||
        !userInfo.phoneNumber ||
        !userInfo.postalNumber
      ) {
        setMissingInfo(true);
        setShowInfoPopup(true); // Show popup for missing info
        return;
      }

      setOrderProcessing(true);

      const orderData = {
        userId: user.uid,
        items: cartItems,
        total: cartItems.reduce(
          (sum, item) => sum + item.price * item.quantity,
          0,
        ),
        status: "created",
        createdAt: new Date(),
        userInfo: {
          fullName: userInfo.fullName,
          phoneNumber: userInfo.phoneNumber,
          postalNumber: userInfo.postalNumber,
        },
      };

      try {
        const orderRef = collection(db, "orders");
        await addDoc(orderRef, orderData);

        let photos = cartItems.map((item) => [item.imageURL, item.id]);
        await sendTelegramNotification("New order arrived!");
        await sendTelegramPhotos(photos);

        await clearCart(user.uid); // Clear cart after order
        setCartItems([]); // Clear local cart state
        setOrderProcessing(false);

        alert("Замовленн успішно створено!");
        navigate("/order-confirmation"); // Navigate to confirmation page
      } catch (error) {
        console.error("Error creating order: ", error);
        alert("Failed to create order. Please try again.");
        setOrderProcessing(false);
      }
    } else {
      alert("Твій кошик пустий!");
    }
  };

  // Handle updating user info and automatically closing the popup
  const handleUserInfoUpdated = async (updatedInfo) => {
    setUpdating(true); // Set updating state to true when submission starts

    try {
      // Save updated info to Firestore
      await setDoc(doc(db, "users", user.uid), updatedInfo);

      // Refetch the user info after saving
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        setUserInfo(userDoc.data()); // Update userInfo state with fresh data from Firestore
      }

      setShowInfoPopup(false); // Automatically hide popup after info is updated
      setMissingInfo(false); // Reset missing info state
      setUpdating(false); // Reset updating state
      alert("User info updated successfully!"); // Show success alert
    } catch (error) {
      console.error("Error updating user info: ", error);
      setUpdating(false); // Reset updating state on failure
      alert("Failed to update user info. Please try again.");
    }
  };

  // Detect clicks outside the popup and close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowInfoPopup(false); // Close popup if clicked outside
      }
    };

    if (showInfoPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup listener on unmount
    };
  }, [showInfoPopup]);

  // Calculate the total price
  const totalPrice = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0,
  );

  if (loading) {
    return <p>Завантажуємо кошик...</p>;
  }

  return (
    <div className="cart-container">
      <h2>Твій кошик</h2>

      {cartItems.length === 0 ? (
        <p>Кошик пустий.</p>
      ) : (
        <>
          <div className="cart-items-list">
            {cartItems.map((item) => (
              <div key={item.id} className="cart-item-row">
                <img
                  src={item.imageURL}
                  alt={item.selectedStyle}
                  className="cart-item-image"
                />
                <div className="cart-item-details">
                  {item.render ? (
                    <>
                      <p>
                        <strong>Стиль:</strong> {item.selectedStyle}
                      </p>
                      <p>
                        <strong>Розмір:</strong> {item.selectedSize}
                      </p>
                      <p>
                        <strong>Кількість людей:</strong> {item.numPeople}
                      </p>
                      <p>
                        <strong>Додаткові обʼєкти:</strong>{" "}
                        {item.additionalObjects.join(", ")}
                      </p>
                      <p>
                        <strong>Packaging:</strong> {item.packaging}
                      </p>
                      <p>
                        <strong>Lacquer:</strong> {item.lacquer ? "Yes" : "No"}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        <strong>Розмір:</strong> {item.selectedSize}
                      </p>
                      <p>
                        <strong>Ціна:</strong> {item.price} UAH
                      </p>
                    </>
                  )}
                  <div className="cart-item-quantity">
                    <button
                      onClick={() =>
                        handleQuantityChange(item.id, item.quantity - 1)
                      }
                      disabled={item.quantity <= 1}
                    >
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button
                      onClick={() =>
                        handleQuantityChange(item.id, item.quantity + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => handleRemove(item.id)}
                  className="remove-btn"
                >
                  Видалити
                </button>
              </div>
            ))}
          </div>

          <div className="cart-summary">
            <h3>Total Price: {totalPrice} UAH</h3>
            <button onClick={handleClearCart} className="clear-cart-btn">
              Очистити кошик
            </button>
            <button
              onClick={handleFinalizeOrder}
              className="finalize-order-btn"
              disabled={orderProcessing}
            >
              {orderProcessing ? "Завантажуємо..." : "Замовити"}
            </button>
          </div>
        </>
      )}

      {showInfoPopup && (
        <div className="popup-overlay">
          <div className="popup-content" ref={popupRef}>
            <h3>Додай свою інформацію</h3>
            <ChangeMyInfo
              onUpdate={handleUserInfoUpdated}
              updating={updating}
              callbackOnComplete={handleClosePopup}
            />{" "}
            {/* Reuse ChangeMyInfo component */}
            <button
              className="close-popup-btn"
              onClick={() => setShowInfoPopup(false)}
            >
              Закрити
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
