import React, { useState } from "react";
import "./PriceAndSizes.css"; // External CSS for styling.
import InfoHint from "./InfoHint"; // Importing the InfoHint component.

const PriceAndSizes = () => {
  // Default photo when 'портретна' is selected
  const [selectedPhoto, setSelectedPhoto] = useState("img/album.png");

  // Function to handle photo change
  const handlePhotoChange = (format) => {
    switch (format) {
      case "портретна":
        setSelectedPhoto("img/portrait.png");
        break;
      case "альбомна":
        setSelectedPhoto("img/album.png");
        break;
      case "квадратна":
        setSelectedPhoto("img/square.png");
        break;
      default:
        setSelectedPhoto("img/album.png");
        break;
    }
  };

  return (
    <div className="price-sizes-container">
      {/* Column 1, Row 1 */}
      <div className="column1 row1"></div>

      {/* Column 2, Row 1 */}
      <div className="column2 row1">
        <button
          className="size-button"
          onClick={() => handlePhotoChange("портретна")}
        >
          Портретна
        </button>
        <InfoHint hintText="Портретна або книжкова орієнтація — це вертикальне розташування зображення таким чином, щоб його висота перевищувала ширину." />
        <button
          className="size-button"
          onClick={() => handlePhotoChange("альбомна")}
        >
          Альбомна
        </button>
        <InfoHint hintText="Альбомна орієнтація — це горизонтальне розташування зображення таким чином, щоб його ширина перевищувала " />
        <button
          className="size-button"
          onClick={() => handlePhotoChange("квадратна")}
        >
          Квадратна
        </button>
        <InfoHint hintText="Квадратний формат картини — ширина та висота картини є однаковими." />
      </div>

      {/* Column 1, Row 2 */}
      <div className="column1 row2">
        <p>
          1. Дана варість включає в себе:
          <ul className="price-n-sizes-list">
            <li>Покращення якості фото</li>
            <li>Напівсентетичне полотно</li>
            <li>Друк УФ-стійкими чорнилами</li>
            <li>Галерейна натяжка полотна на підрамник</li>
            <li>Металева підвіска для картини</li>
            <li>Лакування</li>
            <li>
              Пакування для відправки (замовнику не потрібно переплачувати за
              пакування на пошті).
            </li>
          </ul>
        </p>
        <p>
          2. Дана вартість не включає в себе:
          <br />
          <ul className="price-n-sizes-list">
            <li>Подарункове пакування</li>
            <li>Промальовку / додавання стилю нашими художниками</li>
            <li>Друк на бавовні</li>
            <li>Клейка стрічка для картини</li>
            <li>Текстурний лак</li>
          </ul>
        </p>
      </div>

      {/* Column 2, Row 2 */}
      <div className="column2 row2">
        <img
          src={selectedPhoto}
          alt="Selected Format"
          className="price-photo-preview"
        />
      </div>
    </div>
  );
};

export default PriceAndSizes;
