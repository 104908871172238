import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./PriceCalculator.css"; // Add your styles
import { storage } from "../core/firebase"; // Import Firebase Storage for handling photo uploads
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase storage methods
import { addItemToCart } from "../services/cartService"; // Import cart service
import { UserContext } from "./UserContext"; // Import user context
import InfoHint from "./InfoHint";
import SignInPopup from "./SignInPopup";

const PriceCalculator = () => {
  const user = useContext(UserContext); // Get the user context
  const navigate = useNavigate();
  const location = useLocation();

  // State for fields
  const [artStyles, setArtStyles] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [additionalObjects, setAdditionalObjects] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [numPeople, setNumPeople] = useState(0);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [packaging, setPackaging] = useState("gift");
  const [gift, setGift] = useState(false);
  const [lacquer, setLacquer] = useState(false);
  const [bavovna, setBavovna] = useState(false);
  const [potal, setPotal] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null); // For storing uploaded photo URL
  const [showPopup, setShowPopup] = useState(false);

  // Fetch art styles, sizes, and additional objects from JSON
  useEffect(() => {
    const fetchArtStyles = async () => {
      try {
        const response = await fetch("/data/artstyles.json");
        const data = await response.json();
        setArtStyles(data.artStyles);

        const params = new URLSearchParams(location.search);
        const styleId = params.get("styleId");
        if (styleId) {
          const style = data.artStyles.find((s) => s.id === styleId);
          if (style) {
            setSelectedStyle(style);
          }
        }
      } catch (error) {
        console.error("Error fetching art styles:", error);
      }
    };

    const fetchSizes = async () => {
      try {
        const response = await fetch("/data/sizes.json");
        const data = await response.json();
        setSizes(data.sizes);
        setSelectedSize(data.sizes[0].name);
      } catch (error) {
        console.error("Error fetching sizes:", error);
      }
    };

    const fetchAdditionalObjects = async () => {
      try {
        const response = await fetch("/data/additionalObjects.json");
        const data = await response.json();
        setAdditionalObjects(data.objects);
      } catch (error) {
        console.error("Error fetching additional objects:", error);
      }
    };

    fetchArtStyles();
    fetchSizes();
    fetchAdditionalObjects();
  }, [location.search]);

  // Calculate price based on selected options
  const calculatePrice = () => {
    let price = 0; // Base price

    // Add style price
    if (selectedStyle) {
      price += selectedStyle.price;
    }

    const size = sizes.find((s) => s.name === selectedSize);
    // Size price adjustment
    if (selectedSize) {
      if (size) {
        price += size.price;
      }
    }

    if (selectedStyle) {
      // Price per person
      price +=
        numPeople === "Більше..."
          ? 10 * selectedStyle.personPrice
          : numPeople * selectedStyle.personPrice;
    }

    // Additional objects
    selectedObjects.forEach((object) => {
      const obj = additionalObjects.find((o) => o.name === object);
      if (obj) {
        price += obj.price;
      }
    });

    if (gift) {
      price += size.giftPackagePrice;
    }

    if (potal) {
      price += size.patalPrice;
    }

    if (bavovna) {
      price += 100;
    }

    if (lacquer) {
      price += size.lacquerPrice;
    }

    return price;
  };

  // Firebase photo upload logic
  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const uuid = crypto.randomUUID(); // Generate a UUID using browser's crypto API
      const fileExtension = file.name.split(".").pop(); // Extract file extension
      const storageRef = ref(storage, `photos/${uuid}.${fileExtension}`); // Use UUID for the file name

      try {
        // Upload the photo to Firebase Storage
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setUploadedPhotoURL(downloadURL); // Set the uploaded photo URL
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  // Handle file change (drag and drop or upload)
  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      handlePhotoUpload(e);
    }
  };

  // Handle drag over and drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const uploadedFile = e.dataTransfer.files[0];
    if (uploadedFile) {
      handlePhotoUpload({ target: { files: [uploadedFile] } });
    }
  };

  // Clear selections
  const handleClear = () => {
    setSelectedSize(sizes[0].name);
    setNumPeople(0);
    setSelectedObjects([]);
    setLacquer(false);
    setFile(null);
    setUploadedPhotoURL(null);
    setSelectedStyle(null);
    setGift(false);
    setPotal(false);
    setBavovna(false);
  };

  // Handle adding item to cart
  const handleOrderClick = async () => {
    if (!user) {
      setShowPopup(true);
      alert("Please sign in to place an order then click order again.");
      return;
    }

    if (user && uploadedPhotoURL && selectedSize && selectedStyle) {
      const newItem = {
        id: crypto.randomUUID(),
        render: true, // Generate a UUID for the cart item
        imageURL: uploadedPhotoURL, // Use the uploaded photo URL
        selectedSize: selectedSize, // Store the selected size
        selectedStyle: selectedStyle.name, // Store the selected style
        numPeople: numPeople, // Store number of people
        additionalObjects: selectedObjects, // Store selected additional objects
        packaging: packaging, // Store packaging option
        lacquer: lacquer, // Store lacquer option
        gift: gift, // Store gift option
        bavovna: bavovna, // Store bavovna option
        potal: potal, // Store potal option
        price: calculatePrice(), // Store the calculated price
        quantity: 1, // Set quantity to 1
      };

      try {
        await addItemToCart(user.uid, newItem); // Add the item to Firestore
        alert("Item added to cart!");
        navigate("/cart"); // Navigate to the cart page after adding the item
      } catch (error) {
        console.error("Error adding item to cart:", error);
      }
    } else {
      alert(
        "Please complete all selections and upload a photo before ordering.",
      );
    }
  };

  const isOrderButtonActive = selectedSize && selectedStyle && uploadedPhotoURL;

  return (
    <div className="price-calculator">
      <div className="calculator-top">
        <div className="columns-container">
          {/* Style Selection */}
          <div className="column">
            <h4>Стиль</h4>
            {artStyles.map((style) => (
              <label key={style.id} className="radio-label">
                <input
                  type="radio"
                  name="style"
                  value={style.id}
                  checked={selectedStyle?.id === style.id}
                  onChange={() => setSelectedStyle(style)}
                />
                {style.name}
              </label>
            ))}
          </div>

          {/* Size Section */}
          <div className="column column-size">
            <h4>Розмір</h4>
            {sizes.map((size) => (
              <label key={size.name} className="radio-label">
                <input
                  type="radio"
                  name="size"
                  value={size.name}
                  checked={selectedSize === size.name}
                  onChange={() => setSelectedSize(size.name)}
                />
                {size.name}
              </label>
            ))}
          </div>

          {/* Number of People Section */}
          <div className="column">
            <h4>
              Кількість людей на фото
              <InfoHint hintText="Щобільше людей на фото – тим більше промальовки від художника вимагається, відповідно і ціна змінюватиметься згідно складності фото для картини." />
            </h4>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "Більше..."].map((option) => (
              <label key={option} className="checkbox-label">
                <input
                  type="checkbox"
                  checked={numPeople === option}
                  onChange={() => setNumPeople(option)}
                />
                {option}
              </label>
            ))}
          </div>

          {/* Additional Objects Section */}
          <div className="column">
            <h4>
              Додаткові об’єкти на фото{" "}
              <InfoHint hintText="Чим більше об’єктів на фото – тим більша деталізації та уважність від художника вимагається, аби кожен елемент на картині гармонійно виглядав" />
            </h4>
            {additionalObjects.map((object) => (
              <label key={object.name} className="checkbox-label">
                <input
                  type="checkbox"
                  checked={selectedObjects.includes(object.name)}
                  onChange={() => {
                    setSelectedObjects((prev) =>
                      prev.includes(object.name)
                        ? prev.filter((o) => o !== object.name)
                        : [...prev, object.name],
                    );
                  }}
                />
                {object.name}
                <span className="additional-info">
                  {object && object.hint && <InfoHint hintText={object.hint} />}
                </span>
              </label>
            ))}
          </div>

          {/* Packaging Section */}
          <div className="column">
            <h4>
              Додаткові
              <InfoHint hintText="Замовляєте картину на подарунок, або ж бажаєте урізноманітнити його текстурним лаком на більш високої якості полотні? Не проблема – просто натякніть нам проставивши галочку напроти відповідного пунку." />
            </h4>
            <label className="radio-label">
              <input
                type="checkbox"
                name="gift"
                value="gift"
                checked={gift}
                onChange={() => setGift(!gift)}
              />
              Подарункове пакування
            </label>
            <label className="radio-label">
              <input
                type="checkbox"
                name="packaging"
                checked={lacquer}
                value="transport"
                onChange={() => setLacquer(!lacquer)}
              />
              Нанесення текстурного лаку
              <InfoHint hintText="3D текстурний лак має не тільки захисну, а ще й естетичну функцію. Наноситься нашими художниками вручну пензлем, як прозора текстурна паста для отримання об'ємної поверхні з ефектом масляних мазків на полотні." />
            </label>
            <label className="radio-label">
              <input
                type="checkbox"
                name="packaging"
                checked={bavovna}
                value="transport"
                onChange={() => setBavovna(!bavovna)}
              />
              Натуральне полотно (бавовна)
            </label>
            <label className="radio-label">
              <input
                type="checkbox"
                name="packaging"
                value="transport"
                checked={potal}
                onChange={() => setPotal(!potal)}
              />
              Нанесення поталі
              <InfoHint hintText="Надайте своїм картинам cяючого золотистого, мідного або сріблястий відтінку за допомогою нанесення поталі безпосередньо на полотно. Це робиться вручну художником,що надає вашій картині індивідуальності та розкішний вигляд." />
            </label>
          </div>
        </div>
      </div>

      {/* Bottom Part with Upload and Price */}
      <div className="calculator-bottom">
        {/* Left Column: File Upload */}
        <div className="upload-column">
          <div
            className="upload-area"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload" className="upload-label">
              {file ? file.name : "Тицніть або перетягніть сюди фото"}
            </label>
          </div>

          {/* Photo Preview */}
          {uploadedPhotoURL && (
            <div className="photo-preview">
              <img src={uploadedPhotoURL} alt="Uploaded Preview" />
            </div>
          )}
        </div>

        {/* Right Column: Price and Actions */}
        <div className="price-column">
          <div className="price-section">
            <h3>Ціна: {calculatePrice()} грн*</h3>
            <button onClick={handleClear} className="clear-button">
              Очистити
            </button>
            <button
              onClick={handleOrderClick}
              className="order-button"
              disabled={!isOrderButtonActive}
            >
              Замовити
            </button>
            {showPopup && (
              <SignInPopup closePopup={() => setShowPopup(false)} />
            )}
            {!isOrderButtonActive && (
              <p className="roomlayout-hint">
                Будь ласка, заповніть всі поля та завантажте фото.
              </p>
            )}

            <div className="price-disclaimer">
              * Ціна є орієнтовною та буде остаточно погоджена після дзвінка
              нашого менеджера
              <br />
              ** Ціна не включає вартість доставки
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCalculator;
