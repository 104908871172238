import React, { useState, useEffect } from "react";
import "./UserCabinet.css";
import ChangeMyInfo from "./ChangeMyInfo";
import MyInfo from "./MyInfo";
import OrdersHistory from "./OrdersHistory";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../core/firebase";
import { getAuth } from "firebase/auth";

const OrderHistory = () => <div>Order History Component</div>;
const Quit = () => <div>Quit Component</div>;

const UserCabinet = () => {
  const navigate = useNavigate();
  // State to manage the selected component
  const [selectedComponent, setSelectedComponent] = useState("MyInfo");
  const [isAdmin, setIsAdmin] = useState(false);

  const hashToComponent = {
    "#myinfo": "MyInfo",
    "#changemyinfo": "ChangeMyInfo",
    "#orderhistory": "OrderHistory",
    "#quit": "Quit",
  };

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims;
        if (claims.admin || claims.superAdmin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    });
  }, []);

  useEffect(() => {
    // Check URL hash and set the corresponding component
    const hash = window.location.hash;
    if (hash && hashToComponent[hash]) {
      setSelectedComponent(hashToComponent[hash]);
    } else {
      setSelectedComponent("MyInfo"); // Default to "My Info"
    }

    // Listen for changes in the hash to update the component dynamically
    const handleHashChange = () => {
      const updatedHash = window.location.hash;
      if (updatedHash && hashToComponent[updatedHash]) {
        setSelectedComponent(hashToComponent[updatedHash]);
      }
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // Function to render the selected component on the right side
  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "MyInfo":
        return <MyInfo />;
      case "ChangeMyInfo":
        return <ChangeMyInfo />;
      case "OrderHistory":
        return <OrdersHistory />;
      case "Quit":
        return <Quit />;
      default:
        return <MyInfo />;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth); // Trigger Firebase logout
      navigate("/"); // Redirect to the main page
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div className="personal-cabinet">
      {/* Left column: Menu */}
      <div className="menu">
        <ul>
          <li
            className={selectedComponent === "MyInfo" ? "active" : ""}
            onClick={() => setSelectedComponent("MyInfo")}
          >
            Моя Інформація
          </li>
          <li
            className={selectedComponent === "ChangeMyInfo" ? "active" : ""}
            onClick={() => setSelectedComponent("ChangeMyInfo")}
          >
            Змінити Контактні Дані
          </li>
          <li
            className={selectedComponent === "OrderHistory" ? "active" : ""}
            onClick={() => setSelectedComponent("OrderHistory")}
          >
            Історія Замовлень
          </li>
          {isAdmin && (
            <li onClick={() => navigate("/admin")}>Admin Dashboard</li>
          )}
        </ul>
      </div>

      {/* Right column: Selected Component */}
      <div className="content">{renderSelectedComponent()}</div>
    </div>
  );
};

export default UserCabinet;
