import React from "react";
import PriceCalculator from "../components/PriceCaculator";

const Calc = () => {
  return (
    <div>
      <PriceCalculator />
    </div>
  );
};

export default Calc;
