import React from "react";
import "./AdminDashboard.css";

const Sidebar = ({ onSelectComponent, selectedComponent }) => {
  return (
    <div className="admin-dashboard-sidebar">
      <h2>Admin Panel</h2>
      <ul className="admin-dashboard-menu">
        <li
          onClick={() => onSelectComponent("Dashboard")}
          className={selectedComponent === "Dashboard" ? "active" : ""}
        >
          Dashboard
        </li>
        <li
          onClick={() => onSelectComponent("Orders")}
          className={selectedComponent === "Orders" ? "active" : ""}
        >
          Замовлення
        </li>
        <li
          onClick={() => onSelectComponent("Users")}
          className={selectedComponent === "Users" ? "active" : ""}
        >
          Користувачі
        </li>
        <li
          onClick={() => onSelectComponent("Integrations")}
          className={selectedComponent === "Integrations" ? "active" : ""}
        >
          Інтеграції
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
