import React, { useState, useEffect } from "react";
import ProductGrid from "../components/ProductGrid";
import Steps from "../components/Steps";
import Reviews from "../components/Reviews";
import LatestWorks from "../components/LatestWorks";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetching the products list from the server
    fetch("/data/products-data-front.json")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data); // Set the fetched products
        setLoading(false); // Stop loading state
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading products...</div>; // Show loading message while fetching
  }

  return (
    <div>
      <ProductGrid products={products} />
      <Steps />
      <Reviews />
      <LatestWorks />
    </div>
  );
};

export default Home;
