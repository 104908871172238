import React from "react";
import "./About.css"; // External CSS for styling.

const About = () => {
  return (
    <div className="about-container">
      {/* Column 1: Image */}
      <div className="about-column1">
        <img
          src="/img/Logo_girl_trans.png"
          alt="About Us"
          className="about-image"
        />
      </div>

      {/* Column 2: Text */}
      <div className="about-column2">
        <p className="about-text">
          <span className="about-highlight">
            <span className="about-orange">
              «AR<span className="about-grey">TM</span>E Holst»
            </span>
          </span>{" "}
          — ми яскрава, молода, творча студія, що присвячена пошуку нових
          горизонтів та вертикалей у мистецтві та дизайні. Заснована командою
          пристрасних митців, ми поєднуємо свіжі погляди з інноваційними
          техніками, щоб створювати унікальні та надихаючі роботи, які
          захоплюватимуть людей.
        </p>
        <p className="about-text">
          <span className="about-highlight">
            <span className="about-orange">Наша місія</span>{" "}
            <span className="about-grey"></span>
          </span>{" "}
          — робити увіковічення яскравих моментів в нашому житті, які
          залишаються в пам'яті назавжди. Незалежно від того, чи це цифрове
          мистецтво чи традиційні засоби вираження, ми прагнемо створювати
          твори, які не лише відображають наш динамічний дух, але й глибоко
          резонують з нашою аудиторією.
        </p>
        <p className="about-text">
          У{" "}
          <span className="about-highlight">
            <span className="about-orange">
              «AR<span className="about-grey">TM</span>E Holst»
            </span>
          </span>{" "}
          мистецтво — це більше, ніж просто професія, це стиль життя, і ми раді
          поділитися цими моментами з нашими клієнтами.
        </p>
      </div>
    </div>
  );
};

export default About;
