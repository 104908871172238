import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  HomeRounded,
  ContactMailRounded,
  LocalShippingRounded,
  AccessTimeRounded,
  ShoppingCartRounded,
  PersonRounded,
  LoginRounded,
  ExitToAppRounded,
} from "@mui/icons-material";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import "./Navbar.css";
import { UserContext } from "./UserContext";
import SignInPopup from "./SignInPopup";
import { auth, db } from "../core/firebase";
import { signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const user = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const location = useLocation();

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const totalItems = cartItems.length;
  const totalPrice = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0,
  );

  useEffect(() => {
    if (user) {
      const cartRef = doc(db, "carts", user.uid);
      const unsubscribe = onSnapshot(cartRef, (doc) => {
        if (doc.exists()) {
          const items = doc.data().items || [];
          setCartItems(items);
        }
      });
      return () => unsubscribe();
    }
  }, [user]);

  const isActive = (path) => location.pathname === path;

  const drawerItems = (
    <div className="drawer-content">
      {/* Logo in Drawer */}
      <div className="logo">
        <img src="/img/Logo_girl_trans.png" className="left-img" alt="logo" />
        <a href="/">
          <img src="/img/Logo_letters_2.png" className="right-img" alt="logo" />
        </a>
      </div>
      <Divider />
      <List>
        {[
          { text: "Головна", path: "/" },
          { text: "Про нас", path: "/about" },
          { text: "Галерея", path: "/gallery" },
          { text: "Стилі", path: "/styles" },
          { text: "Розміри/Ціни", path: "/price" },
          { text: "Замовити з промальовкою", path: "/order/1" },
          { text: "Замовити без промальовки", path: "/order/2" },
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              onClick={() => toggleDrawer(false)}
              className={`drawer-item ${isActive(item.path) ? "active" : ""}`}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{ align: "center" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <div className="upper-navbar">
        <div className="left-section">
          <IconButton
            className="mobile-drawer-icon"
            onClick={() => toggleDrawer(true)}
            sx={{ color: "white" }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" title="Головна сторінка">
            <HomeRounded />
          </Link>
          <Link to="/contact" title="Наші контакти">
            <ContactMailRounded />
          </Link>
          <Link
            to="/other?section=paymentAndDelivery"
            title="Доставка і оплата"
          >
            <LocalShippingRounded />
          </Link>
          <Link to="/other?section=productionTime" title="Терміни виготовлення">
            <AccessTimeRounded />
          </Link>
        </div>
        <div className="right-section icons">
          <Link to="/cart" title="Cart">
            <ShoppingCartRounded /> {totalItems} ({totalPrice} UAH)
          </Link>
          {user ? (
            <>
              <Link to="/user" title="Мої налаштування, історія замолень">
                <PersonRounded /> Привіт, {user.displayName || user.email}
              </Link>
              <a
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
                title="Вийти із сайту"
              >
                Вийти <ExitToAppRounded />
              </a>
            </>
          ) : (
            <a
              onClick={() => setShowPopup(true)}
              style={{ cursor: "pointer" }}
              title="Вхід до сайту"
            >
              Увійти <LoginRounded />
            </a>
          )}
        </div>
      </div>
      <nav className="navbar">
        <div className="logo">
          <img src="/img/Logo_girl_trans.png" className="left-img" alt="logo" />
          <a href="/">
            <img
              src="/img/Logo_letters_2.png"
              className="right-img"
              alt="logo"
            />
          </a>
        </div>
        <div className="nav-links styled">
          <Link
            to="/about"
            className={`${isActive("/about") ? "active" : ""} hide-on-small`}
          >
            Про нас
          </Link>
          <Link to="/gallery" className={isActive("/gallery") ? "active" : ""}>
            Галерея
          </Link>
          <Link to="/styles" className={isActive("/styles") ? "active" : ""}>
            Стилі
          </Link>
          <Link to="/price" className={isActive("/price") ? "active" : ""}>
            Розміри/Ціни
          </Link>
          <Link
            to="/order/1"
            className={`blue-border ${isActive("/order/1") ? "active" : ""}`}
          >
            Замовити з промальовкою
          </Link>
          <Link
            to="/order/2"
            className={`orange-border ${isActive("/order/2") ? "active" : ""}`}
          >
            Замовити без промальовки
          </Link>
        </div>
        {/* Drawer */}
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => toggleDrawer(false)}
        >
          {drawerItems}
        </Drawer>
      </nav>
      {showPopup && <SignInPopup closePopup={() => setShowPopup(false)} />}
    </div>
  );
};

export default Navbar;
