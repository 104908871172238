import React from "react";
import UserCabinet from "../components/UserCabinet";

const User = () => {
  return (
    <div>
      <UserCabinet />
    </div>
  );
};

export default User;
