import React from "react";
import ProductCard from "./ProductCard";
import "./ProductGrid.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import leftArrow from "../assets/arrow-left.png"; // Adjust path as needed

const ProductGrid = ({ products }) => {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: "30px",
          zIndex: 1,
          cursor: "pointer",
          transform: "rotate(180deg)", // Rotate the left arrow to make it a right arrow
        }}
        onClick={onClick}
      >
        <img
          src={leftArrow}
          alt="Next"
          style={{ width: "40px", height: "70px" }}
        />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          left: "30px",
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <img
          src={leftArrow}
          alt="Previous"
          style={{ width: "40px", height: "70px" }}
        />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024, // Adjust for tablets and larger phones
        settings: {
          slidesToShow: 4, // Show 4 items on tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Adjust for smaller screens like mobile
        settings: {
          slidesToShow: 2, // Show 2 items on mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Adjust for very small screens
        settings: {
          slidesToShow: 1, // Show 1 item on very small mobile screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="product-grid-container">
      <Slider {...settings}>
        {products.map((product, index) => (
          <div key={index}>
            <ProductCard
              image={product.img}
              styleId={product.styleId}
              description={product.styleDesc}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductGrid;
