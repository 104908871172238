import React, { useState, useRef, useEffect } from "react";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Rect,
  Transformer,
} from "react-konva";
import useImage from "../components/UseImage"; // Custom hook to load images

const ImageCalibrator = ({ roomImageUrl, onCalibrate, initialPlaceholder }) => {
  const [roomImage] = useImage(roomImageUrl); // Room image
  const [naturalSize, setNaturalSize] = useState({ width: 0, height: 0 }); // Natural size of the image
  const [rectProps, setRectProps] = useState({
    x: initialPlaceholder.x,
    y: initialPlaceholder.y,
    width: initialPlaceholder.width || 100, // Initial width (modifiable)
    height: initialPlaceholder.height || 100, // Initial height (modifiable)
  });
  const [isSelected, setIsSelected] = useState(false); // To track if the rectangle is selected
  const rectRef = useRef(); // Reference to the rectangle
  const transformerRef = useRef(); // Reference to the transformer

  useEffect(() => {
    if (roomImage) {
      const img = roomImage;
      // Set the natural width and height of the image
      setNaturalSize({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    }
  }, [roomImage]);

  // Automatically attach the transformer to the rectangle when selected
  useEffect(() => {
    if (isSelected && transformerRef.current && rectRef.current) {
      transformerRef.current.nodes([rectRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleDragEnd = (e) => {
    const { x, y } = e.target.position();
    setRectProps((prevProps) => ({ ...prevProps, x, y }));
    normalizeAndSave({ ...rectProps, x, y });
  };

  const handleTransformEnd = (e) => {
    const node = e.target;
    const width = node.width() * node.scaleX(); // Get the scaled width
    const height = node.height() * node.scaleY(); // Get the scaled height

    node.scaleX(1); // Reset scale to 1 after resizing
    node.scaleY(1);

    setRectProps((prevProps) => ({ ...prevProps, width, height }));
    normalizeAndSave({ x: rectProps.x, y: rectProps.y, width, height });
  };

  // Normalize the coordinates and save both position and size
  const normalizeAndSave = (data) => {
    const normalizedData = {
      "placeholder-X": (data.x / window.innerWidth) * naturalSize.width,
      "placeholder-Y": (data.y / window.innerHeight) * naturalSize.height,
      width: (data.width / window.innerWidth) * naturalSize.width,
      height: (data.height / window.innerHeight) * naturalSize.height,
    };

    onCalibrate(normalizedData); // Pass the normalized data back
  };

  return (
    <div>
      <Stage width={window.innerWidth} height={window.innerHeight}>
        <Layer>
          {/* Room Image */}
          {roomImage && (
            <KonvaImage
              image={roomImage}
              x={0}
              y={0}
              width={window.innerWidth}
              height={window.innerHeight}
            />
          )}

          {/* Draggable and Resizable Rectangle */}
          <Rect
            {...rectProps}
            fill="rgba(0, 128, 255, 0.5)" // Semi-transparent blue
            draggable
            onClick={() => setIsSelected(true)}
            onDragEnd={handleDragEnd}
            onTransformEnd={handleTransformEnd}
            ref={rectRef} // Attach reference to the rectangle
          />

          {/* Transformer: Allows resizing */}
          {isSelected && (
            <Transformer
              ref={transformerRef} // Attach reference to the transformer
              boundBoxFunc={(oldBox, newBox) => {
                // Limit resizing to prevent negative width/height
                if (newBox.width < 20 || newBox.height < 20) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </Layer>
      </Stage>
    </div>
  );
};

export default ImageCalibrator;
