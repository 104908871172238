import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer, Image as KonvaImage } from "react-konva";
import useImage from "./UseImage"; // Custom useImage hook

// RoomWithUserPhoto Component
const RoomWithUserPhoto = ({ roomImageUrl, userImageUrl, greenSquare }) => {
  const stageRef = useRef(null);
  const [roomImage] = useImage(roomImageUrl); // Room image
  const [userImage] = useImage(userImageUrl); // User uploaded image
  const [roomImageOriginalSize, setRoomImageOriginalSize] = useState({
    width: 0,
    height: 0,
  });
  const [stageSize, setStageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [scaleFactor, setScaleFactor] = useState(1); // Scale factor for resizing

  // Green square coordinates and dimensions relative to original image size
  const {
    x: originalX,
    y: originalY,
    width: originalWidth,
    height: originalHeight,
  } = greenSquare;

  // Once the room image is loaded, extract its original size (naturalWidth and naturalHeight)
  useEffect(() => {
    if (roomImage) {
      setRoomImageOriginalSize({
        width: roomImage.naturalWidth,
        height: roomImage.naturalHeight,
      });
    }
  }, [roomImage]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      // Calculate the scale factor to fit the room image to the stage
      if (roomImageOriginalSize.width && roomImageOriginalSize.height) {
        const scaleX = width / roomImageOriginalSize.width;
        const scaleY = height / roomImageOriginalSize.height;
        const scale = Math.min(scaleX, scaleY); // Choose the smaller scale to fit the room image

        setScaleFactor(scale);
        setStageSize({ width, height });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [roomImageOriginalSize]); // Dependency on roomImageOriginalSize to recalculate on resize

  // Fit user image into the green square while maintaining aspect ratio
  const fitUserImage = (image) => {
    if (!image) return { width: 0, height: 0, cropX: 0, cropY: 0 };

    // Get aspect ratios
    const imageAspectRatio = image.width / image.height;
    const squareAspectRatio = originalWidth / originalHeight;

    let newWidth,
      newHeight,
      cropX = 0,
      cropY = 0;

    if (imageAspectRatio > squareAspectRatio) {
      // Image is wider than the square, fit by height and crop width
      newHeight = originalHeight * scaleFactor;
      newWidth = newHeight * imageAspectRatio; // Scale width based on the aspect ratio

      // Center the image horizontally (crop sides)
      cropX = (newWidth - originalWidth * scaleFactor) / 2;
    } else {
      // Image is taller than the square, fit by width and crop height
      newWidth = originalWidth * scaleFactor;
      newHeight = newWidth / imageAspectRatio; // Scale height based on the aspect ratio

      // Center the image vertically (crop top/bottom)
      cropY = (newHeight - originalHeight * scaleFactor) / 2;
    }

    return { newWidth, newHeight, cropX, cropY };
  };

  const { newWidth, newHeight, cropX, cropY } = fitUserImage(userImage);

  return (
    <Stage width={stageSize.width} height={stageSize.height} ref={stageRef}>
      <Layer>
        {/* Room Image */}
        {roomImage && (
          <KonvaImage
            image={roomImage}
            x={0}
            y={0}
            width={roomImageOriginalSize.width * scaleFactor}
            height={roomImageOriginalSize.height * scaleFactor}
          />
        )}

        {/* User Image */}
        {userImage && (
          <KonvaImage
            image={userImage}
            x={originalX * scaleFactor} // Place the image at the correct position
            y={originalY * scaleFactor} // Place the image at the correct position
            width={originalWidth * scaleFactor} // Force the image to fit the width of the green square
            height={originalHeight * scaleFactor} // Force the image to fit the height of the green square
            crop={{
              x: cropX, // Crop sides or top/bottom based on aspect ratio
              y: cropY,
              width: userImage.width - cropX * 2, // Width after cropping
              height: userImage.height - cropY * 2, // Height after cropping
            }}
            draggable // Optional: allow dragging if needed
          />
        )}
      </Layer>
    </Stage>
  );
};

export default RoomWithUserPhoto;
