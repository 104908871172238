import { db } from "../core/firebase"; // Import Firestore instance
import { doc, getDoc, setDoc, arrayUnion, updateDoc } from "firebase/firestore"; // Firestore functions

// Fetch Cart Items
export const fetchCartItems = async (userId) => {
  try {
    const cartRef = doc(db, "carts", userId); // Reference to the user's cart document
    const cartDoc = await getDoc(cartRef); // Get the document

    if (cartDoc.exists()) {
      return cartDoc.data().items || []; // Return items if they exist
    } else {
      return []; // Return an empty array if no cart document exists
    }
  } catch (error) {
    console.error("Error fetching cart items: ", error);
    return [];
  }
};

// Add Item to Cart
export const addItemToCart = async (userId, newItem) => {
  try {
    const cartRef = doc(db, "carts", userId); // Reference to the user's cart document

    // Use arrayUnion to add the new item to the existing array, or create it if it doesn't exist
    await setDoc(cartRef, { items: arrayUnion(newItem) }, { merge: true });

    console.log("Item successfully added to cart");
  } catch (error) {
    console.error("Error adding item to cart: ", error);
  }
};

// Remove Item from Cart
export const removeItemFromCart = async (userId, itemId) => {
  try {
    const cartItems = await fetchCartItems(userId); // Fetch the current cart items
    const updatedCart = cartItems.filter((item) => item.id !== itemId); // Filter out the item to remove

    const cartRef = doc(db, "carts", userId); // Reference to the user's cart document

    // Update the cart document with the filtered items
    await updateDoc(cartRef, { items: updatedCart });

    console.log("Item successfully removed from cart");
  } catch (error) {
    console.error("Error removing item from cart: ", error);
  }
};

// Clear Cart
export const clearCart = async (userId) => {
  try {
    const cartRef = doc(db, "carts", userId); // Reference to the user's cart document

    // Set the items field to an empty array to clear the cart
    await setDoc(cartRef, { items: [] }, { merge: true });

    console.log("Cart successfully cleared");
  } catch (error) {
    console.error("Error clearing cart: ", error);
  }
};

// Update Item Quantity in Cart
export const updateItemQuantity = async (userId, itemId, newQuantity) => {
  try {
    const cartItems = await fetchCartItems(userId); // Fetch the current cart items
    const updatedCart = cartItems.map((item) =>
      item.id === itemId ? { ...item, quantity: newQuantity } : item,
    ); // Update the quantity of the matching item

    const cartRef = doc(db, "carts", userId); // Reference to the user's cart document

    // Update the cart document with the updated items
    await updateDoc(cartRef, { items: updatedCart });

    console.log("Item quantity successfully updated");
  } catch (error) {
    console.error("Error updating item quantity: ", error);
  }
};
