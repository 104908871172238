import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Reviews.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftArrow from "../assets/arrow-left.png"; // Adjust path as needed

const Reviews = () => {
  const [reviewsData, setReviewsData] = useState([]);
  useEffect(() => {
    fetch("/data/reviewsData.json")
      .then((response) => response.json())
      .then((data) => setReviewsData(data.reviews))
      .catch((error) => console.error("Failed to fetch reviews data:", error));
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: "30px",
          zIndex: 1,
          cursor: "pointer",
          transform: "rotate(180deg)", // Rotate the left arrow to make it a right arrow
        }}
        onClick={onClick}
      >
        <img
          src={leftArrow}
          alt="Next"
          style={{ width: "40px", height: "70px" }}
        />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          left: "30px",
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <img
          src={leftArrow}
          alt="Previous"
          style={{ width: "40px", height: "70px" }}
        />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7, // Show 6 cards by default
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4, // Show fewer cards on medium screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2, // Show 2 cards on small screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="reviews-container">
      <h2 className="reviews-title">Відгуки наших клієнтів</h2>
      <div className="reviews-slider-wrapper">
        <Slider {...settings}>
          {reviewsData.map((review, index) => (
            <div key={index} className="review-card">
              <h3>{review.name}</h3>
              <p>{review.date}</p>
              <div className="stars">{"★".repeat(review.rating)}</div>
              <p>{review.comment}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Reviews;
