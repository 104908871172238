import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./LatestWorks.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftArrow from "../assets/arrow-left.png"; // Adjust path as needed

const LatestWorks = () => {
  const [latestWorksData, setLatestWorksData] = useState([]);

  // Fetch gallery data from server
  useEffect(() => {
    fetch("/data/gallery.json")
      .then((response) => response.json())
      .then((data) => {
        // Extract all images from different styles
        const allImages = Object.values(data.gallery).flatMap((style) =>
          style.images.map((img) => ({
            ...img,
            styleId: style.styleId,
            styleName: style.name,
          })),
        );

        // Shuffle and pick 9 random images
        const shuffledImages = allImages.sort(() => 0.5 - Math.random());
        setLatestWorksData(shuffledImages.slice(0, 9));
      })
      .catch((error) => console.error("Failed to fetch gallery data:", error));
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: "30px",
          zIndex: 1,
          cursor: "pointer",
          transform: "rotate(180deg)", // Rotate the left arrow to make it a right arrow
        }}
        onClick={onClick}
      >
        <img
          src={leftArrow}
          alt="Next"
          style={{ width: "40px", height: "70px" }}
        />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          left: "30px",
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <img
          src={leftArrow}
          alt="Previous"
          style={{ width: "40px", height: "70px" }}
        />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="latest-works-container">
      <h2 className="latest-works-title">Наші останні роботи</h2>
      <Slider {...settings}>
        {latestWorksData.map((work, index) => (
          <div key={index} className="latest-works-item">
            <img src={work.url} alt="Artwork" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LatestWorks;
