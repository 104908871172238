import React, { useState, useEffect } from "react";
import ImageCalibrator from "./ImageCalibrator";

const CalibrationSequence = () => {
  const [roomData, setRoomData] = useState([]); // Room data from JSON
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current room index
  const [calibrationData, setCalibrationData] = useState([]); // Store calibrated data
  const [currentRoom, setCurrentRoom] = useState(null); // Current room to display
  const [completed, setCompleted] = useState(false); // Track if all images are done

  // Fetch room data from the server (or a JSON file)
  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await fetch("/data/roomLayoutData.json");
        const data = await response.json();
        setRoomData(data);
        setCurrentRoom(data[0]); // Start with the first room
        setCalibrationData(Array(data.length).fill(null)); // Prepare empty data array
      } catch (error) {
        console.error("Error fetching room data:", error);
      }
    };

    fetchRoomData();
  }, []);

  // Handle calibration for the current room
  const handleCalibrate = (data) => {
    const updatedData = [...calibrationData];
    updatedData[currentIndex] = { ...roomData[currentIndex], ...data };
    setCalibrationData(updatedData);
  };

  // Move to the next room
  const handleNextRoom = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < roomData.length) {
      setCurrentIndex(nextIndex);
      setCurrentRoom(roomData[nextIndex]);
    } else {
      setCompleted(true);
      alert("All images are calibrated!");
    }
  };

  // Download the updated JSON file
  const downloadJSON = () => {
    const blob = new Blob([JSON.stringify(calibrationData, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "calibrated_room_data.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <h1>Room Calibration</h1>
      {currentRoom && (
        <div>
          <p>
            Image {currentIndex + 1} of {roomData.length}
          </p>
          <ImageCalibrator
            roomImageUrl={currentRoom.roompic}
            onCalibrate={handleCalibrate}
            initialPlaceholder={{
              x: currentRoom["placeholder-X"],
              y: currentRoom["placeholder-Y"],
              width: currentRoom.width,
              height: currentRoom.height,
            }}
          />
          <button onClick={handleNextRoom} style={{ marginTop: "10px" }}>
            {currentIndex + 1 === roomData.length
              ? "Complete Calibration"
              : "Complete Image"}
          </button>
        </div>
      )}

      {/* Show the download button only when all rooms are calibrated */}
      {completed && (
        <div style={{ marginTop: "20px" }}>
          <button onClick={downloadJSON}>Download Calibrated Data</button>
        </div>
      )}
    </div>
  );
};

export default CalibrationSequence;
