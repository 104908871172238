import React from "react";
import PriceAndSizes from "../components/PriceAndSizes";

const Price = () => {
  return (
    <div>
      <PriceAndSizes />
    </div>
  );
};

export default Price;
