const API_KEY = "78ee774cc73e4d37d52ba7704b0a70c4"; // Replace with your actual API key

// Fetch cities from Nova Poshta API
export const fetchCities = async () => {
  const response = await fetch("https://api.novaposhta.ua/v2.0/json/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      apiKey: API_KEY,
      modelName: "Address",
      calledMethod: "getCities",
      methodProperties: {},
    }),
  });

  const data = await response.json();
  if (data.success) {
    return data.data;
  } else {
    throw new Error("Failed to fetch cities");
  }
};

// Fetch post departments for a selected city
export const fetchDepartments = async (cityRef) => {
  const response = await fetch("https://api.novaposhta.ua/v2.0/json/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      apiKey: API_KEY,
      modelName: "AddressGeneral",
      calledMethod: "getWarehouses",
      methodProperties: {
        CityRef: cityRef,
      },
    }),
  });

  const data = await response.json();
  if (data.success) {
    return data.data;
  } else {
    throw new Error("Failed to fetch departments");
  }
};
