import React, { useContext, useState, useEffect, useRef } from "react";
import "./RoomLayout.css"; // Separate CSS for styling
import { UserContext } from "./UserContext";
import { addItemToCart } from "../services/cartService";
import { storage } from "../core/firebase"; // Import Firebase Storage for handling photo uploads
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase storage methods
import { useNavigate } from "react-router-dom"; // For navigation after adding to cart
import RoomWithUserPhoto from "./RoomWithUserPhoto"; // Import the RoomWithUserPhoto component

const RoomLayout = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate(); // useNavigate for redirecting to cart
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null); // For storing uploaded photo URL
  const [isOrderButtonActive, setIsOrderButtonActive] = useState(false);
  const fileInputRef = useRef(null); // For handling file input

  // Fetch sizes from the server
  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const response = await fetch("/data/roomLayoutData.json");
        const data = await response.json();
        setSizes(data);
      } catch (error) {
        console.error("Error fetching sizes:", error);
      }
    };

    fetchSizes();
  }, []);

  useEffect(() => {
    if (selectedSize && uploadedPhotoURL) {
      setIsOrderButtonActive(true);
    }
  }, [selectedSize, uploadedPhotoURL]);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);

    // Scroll to the element with the ID "target-section"
    const element = document.getElementById("target-section");
    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: "smooth" }), 10);
    }
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const uuid = crypto.randomUUID(); // Generate a UUID using browser's crypto API
      const fileExtension = file.name.split(".").pop(); // Extract file extension
      const storageRef = ref(storage, `photos/${uuid}.${fileExtension}`); // Use UUID for the file name

      try {
        // Upload the photo to Firebase Storage
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setUploadedPhotoURL(downloadURL); // Set the uploaded photo URL
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleAddToCart = async () => {
    if (user && uploadedPhotoURL && selectedSize) {
      const newItem = {
        id: crypto.randomUUID(), // Generate a UUID for the cart item
        render: false, // Set render to false initially
        imageURL: uploadedPhotoURL, // Use the uploaded photo URL
        selectedSize: selectedSize.name, // Get the selected size
        quantity: 1, // Set quantity to 1
        price: selectedSize.price, // Get the selected size's price
      };

      try {
        await addItemToCart(user.uid, newItem); // Add the item to Firestore
        alert("Item added to cart!");
        navigate("/cart"); // Navigate to the cart page after adding the item
      } catch (error) {
        console.error("Error adding item to cart:", error);
      }
    } else {
      alert("Please select a size and upload a photo before ordering.");
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click(); // Trigger file input
  };

  return (
    <div className="roomlayout-container">
      {/* Left column for buttons */}
      <div className="roomlayout-left-column">
        <div className="roomlayout-dropdown">
          <button className="roomlayout-button">
            {selectedSize
              ? `${selectedSize.name} | Ціна: ${selectedSize.price} UAH`
              : "Обрати розмір"}
          </button>
          <div className="roomlayout-dropdown-content">
            {sizes.length > 0 ? (
              sizes.map((size) => (
                <button
                  key={size.name}
                  className="roomlayout-dropdown-item"
                  onClick={() => handleSizeSelect(size)}
                >
                  {size.name} - {size.price} UAH
                </button>
              ))
            ) : (
              <button className="roomlayout-dropdown-item" disabled>
                Завантажую розміри...
              </button>
            )}
          </div>
        </div>

        <button onClick={handleUploadClick} className="roomlayout-button">
          Завантажити фото
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handlePhotoUpload}
        />

        {/* Display uploaded photo preview */}
        {uploadedPhotoURL && (
          <div className="roomlayout-photo-preview-inline">
            <img
              src={uploadedPhotoURL}
              alt="Uploaded"
              className="roomlayout-uploaded-photo-inline"
            />
          </div>
        )}

        <button disabled={true} className="roomlayout-button-disabled">
          Редактор (Disabled)
        </button>

        <button
          onClick={handleAddToCart}
          className="roomlayout-button"
          disabled={!isOrderButtonActive}
        >
          Замовити
        </button>

        {!isOrderButtonActive && (
          <p className="roomlayout-hint">Вибери розмір та завантаж своє фото</p>
        )}
      </div>

      {/* Right column for the room preview */}
      <div className="roomlayout-right-column" id="target-section">
        {selectedSize && uploadedPhotoURL && (
          <RoomWithUserPhoto
            roomImageUrl={selectedSize.roompic} // Pass room image
            userImageUrl={uploadedPhotoURL} // Pass uploaded user photo
            greenSquare={{
              x: selectedSize["placeholder-X"], // Pass the placeholder's X position
              y: selectedSize["placeholder-Y"], // Pass the placeholder's Y position
              width: selectedSize.width, // Pass the placeholder's width
              height: selectedSize.height, // Pass the placeholder's height
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RoomLayout;
