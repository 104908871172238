import React, { useEffect } from "react";
import "./Contact.css"; // Ensure this is the path to your CSS file

// Define the style for the map container
const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

// Coordinates for Kyiv
const kyivPosition = { lat: 50.438926696777344, lng: 30.43831443786621 };

const Contact = () => {
  useEffect(() => {
    // Function to load Google Maps script
    const loadGoogleMaps = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDl3hprjo1IoikLZbZT77eYsVwW1EcHAPg&libraries=marker&v=beta`;
      script.async = true;
      script.defer = true;
      script.onload = () => initializeMap(); // Call initializeMap when the script is loaded
      document.head.appendChild(script);
    };

    // Function to initialize the Google Map and add the Advanced Marker
    const initializeMap = () => {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 14, // Adjust zoom level to better fit Kyiv
        center: kyivPosition, // Set the center of the map to Kyiv
        mapId: "4dccbd827fadc992", // Your valid Map ID
      });

      // Create and place the AdvancedMarkerElement on the map
      const advancedMarker =
        new window.google.maps.marker.AdvancedMarkerElement({
          map, // Attach the marker to the map
          position: kyivPosition, // Marker position for Kyiv
          title: "Kyiv", // Marker title on hover
        });
    };

    // Check if Google Maps is already loaded, else load it
    if (!window.google || !window.google.maps) {
      loadGoogleMaps(); // Load Google Maps script if not already loaded
    } else {
      initializeMap(); // If already loaded, initialize the map
    }
  }, []);

  return (
    <div className="contact-container">
      {/* Column 1: Image */}
      <div className="contact-column1">
        <img
          src="/img/Logo_girl_trans.png"
          alt="Contact Us"
          className="contact-image"
        />
      </div>

      {/* Column 2: Contact Details */}
      <div className="contact-column2">
        <h2 className="contact-heading">Контакти</h2>
        <ul className="contact-info">
          <li>Наші соціальні мережі:</li>
          <li>
            <div className="contact-social-links">
              <a href="https://t.me/ARTME_holst">
                <img src="/img/icons/tg.png" alt="Telegram" />
              </a>
              <a href="#">
                <img src="/img/icons/vber.png" alt="Viber" />
              </a>
              <a href="https://instagram.com/artme_holst">
                <img src="/img/icons/ig.png" alt="Instagram" />
              </a>
              <a href="#">
                <img src="/img/icons/tt.png" alt="TikTok" />
              </a>
            </div>
          </li>
          <li>Телефони:</li>
          <li>
            <div className="contact-phone-links">
              <a href="tel:9051290512">
                <img src="/img/icons/vodafone.png" alt="Vodafone" />
              </a>
              <a href="tel:9051290512">
                <img src="/img/icons/lifecell.png" alt="Lifecell" />
              </a>
              <a href="tel:9051290512">
                <img src="/img/icons/kyivstar.png" alt="Kyivstar" />
              </a>
            </div>
          </li>
          <li>Адреса: м. Київ, вул. Миколи Голего, буд. 7В</li>
          <li>
            <div
              className="map-container"
              id="map"
              style={mapContainerStyle}
            ></div>
          </li>
        </ul>

        {/* Working Hours */}
        <div className="contact-hours">
          <h3>Графік роботи:</h3>
          <p>
            Пн – Сб: <span className="contact-orange">09:00 – 21:00</span>
          </p>
          <p>
            Нд: <span className="contact-orange">Вихідний</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
