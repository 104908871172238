import React, { useState } from "react";
import { auth, googleProvider, appleProvider } from "../core/firebase";
import {
  signInWithPopup,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import "./SignInPopup.css";

const SignInPopup = ({ closePopup }) => {
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      closePopup();
    } catch (error) {
      console.error("Error during Google sign-in:", error);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      await signInWithPopup(auth, appleProvider);
      closePopup();
    } catch (error) {
      console.error("Error during Apple sign-in:", error);
    }
  };

  const handlePhoneSignIn = async () => {
    try {
      if (!window.recaptchaVerifier) {
        // Initialize reCAPTCHA verifier
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved, allow user to proceed with phone sign-in.
              console.log("reCAPTCHA solved!");
            },
          },
          auth,
        );
      }
      // Disable app verification for testing
      auth.settings.appVerificationDisabledForTesting = true;

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier,
      );
      setVerificationId(confirmationResult.verificationId);
    } catch (error) {
      console.error("Error during phone sign-in:", error);
    }
  };

  const handlePhoneVerification = async () => {
    try {
      const credential = auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );
      await auth.signInWithCredential(credential);
      closePopup();
    } catch (error) {
      console.error("Error verifying phone code:", error);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      closePopup();
    }
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        {!showPhoneInput ? (
          <>
            <h2>Sign in</h2>
            <button onClick={handleGoogleSignIn}>Sign in with Google</button>
            <button onClick={handleAppleSignIn} disabled>
              Sign in with Apple
            </button>
            <button onClick={() => setShowPhoneInput(true)} disabled>
              Sign in with Phone Number
            </button>
          </>
        ) : (
          <>
            {!verificationId ? (
              <>
                <h2>Enter your Phone Number</h2>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Phone number"
                />
                <div id="recaptcha-container"></div>
                <button onClick={handlePhoneSignIn}>Send Code</button>
                <button onClick={() => setShowPhoneInput(false)}>Back</button>
              </>
            ) : (
              <>
                <h2>Enter the Verification Code</h2>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Verification code"
                />
                <button onClick={handlePhoneVerification}>Verify Code</button>
                <button onClick={() => setShowPhoneInput(false)}>Back</button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SignInPopup;
