import React, { useState } from "react";
import "./InfoHint.css";

const InfoHint = ({ hintText }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="info-hint-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="info-circle">i</div>
      {isHovered && <div className="hint-box">{hintText}</div>}
    </div>
  );
};

export default InfoHint;
